import Transition, {
  TransitionVariant
} from '@aurora/shared-client/components/common/Transition/Transition';
import React, { useRef, useState } from 'react';
import type { OverlayInjectedProps } from 'react-bootstrap/lib/esm/Overlay';
import type {
  OverlayTriggerProps,
  OverlayTriggerRenderProps
} from 'react-bootstrap/lib/esm/OverlayTrigger';
import { OverlayTrigger } from 'react-bootstrap';
import { hoverCardPopperConfig } from '../HoverCardHelper';

interface MouseHandlers {
  onMouseEnter: React.MouseEventHandler<HTMLElement>;
  onMouseLeave: React.MouseEventHandler<HTMLElement>;
}

export type ExtendedOverlayInjectedProps = Omit<
  OverlayInjectedProps,
  'arrowProps' | 'placement' | 'popper' | 'show'
> &
  MouseHandlers;
export type ExtendedOverlayTriggerInjectedProps = OverlayTriggerRenderProps & MouseHandlers;

interface Props
  extends Omit<
    OverlayTriggerProps,
    'show' | 'onToggle' | 'delay' | 'placement' | 'trigger' | 'overlay' | 'children'
  > {
  overlay: React.FC<React.PropsWithChildren<ExtendedOverlayInjectedProps>>;
  children: React.FC<React.PropsWithChildren<ExtendedOverlayTriggerInjectedProps>>;
}

/**
 * Wrapper around the react-bootstrap `OverlayTrigger` that adds support for the Overlay
 * to remain open when the mouse is inside the Overlay element, not just inside the trigger
 * element. To facilitate this functionality, the injected props for both the specified
 * Overlay and Trigger components are provided with callback functions for `onMouseEnter`
 * and `onMouseLeave` that must be used on the outermost native DOM elements of the
 * rendering components.
 *
 * This wrapper enforces that the overlay appears on hover and focus, with a pre-defined
 * delay for showing and hiding the overlay.
 *
 * @author Adam Ayres, Neha Anandpara
 */
const ExtendedOverlayTrigger: React.FC<Props> = props => {
  const { overlay, children, ...remainingProps } = props;
  const inPopoverRef = useRef(false);
  const inTriggerRef = useRef(false);
  const [show, setShow] = useState(false);
  const showDelay = 400;
  const hideDelay = 200;

  function handleToggle(updatedShow = false) {
    setShow(updatedShow || inPopoverRef.current || inTriggerRef.current);
  }

  function toggleRef(ref, state) {
    ref.current = state;
  }

  function handlePopoverMouseLeave() {
    toggleRef(inPopoverRef, false);
    setTimeout(() => {
      handleToggle();
    }, hideDelay);
  }

  function renderOverlay(popoverProps: OverlayInjectedProps) {
    return (
      <Transition variant={TransitionVariant.FADE_SLOW} in={show} appear unmountOnExit>
        {overlay({
          ...popoverProps,
          onMouseEnter: () => toggleRef(inPopoverRef, true),
          onMouseLeave: () => handlePopoverMouseLeave()
        })}
      </Transition>
    );
  }

  function renderTrigger(triggerProps: OverlayTriggerRenderProps) {
    return children({
      ...triggerProps,
      onMouseEnter: () => toggleRef(inTriggerRef, true),
      onMouseLeave: () => toggleRef(inTriggerRef, false)
    });
  }

  return (
    <OverlayTrigger
      /* eslint-disable react/jsx-props-no-spreading */
      {...remainingProps}
      show={show}
      delay={{ show: showDelay, hide: hideDelay }}
      overlay={overlayProps => renderOverlay(overlayProps)}
      onToggle={updatedShow => handleToggle(updatedShow)}
      trigger={['focus', 'hover']}
      placement="auto"
      popperConfig={hoverCardPopperConfig()}
      transition={false}
    >
      {triggerProps => renderTrigger(triggerProps)}
    </OverlayTrigger>
  );
};

export default ExtendedOverlayTrigger;
